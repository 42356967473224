import { createContext } from 'unctx';
import type { NuxtApp } from '#app';

const ctx = createContext<NuxtApp>();

export function getNuxtApp() {
  return ctx.tryUse();
}

export function setNuxtApp(app: NuxtApp) {
  ctx.set(app, true);
}
