import type { ApiOptionalParamTy, ApiRequiredParamTy, ApiRespTy } from './types';

export const enum Py {
  OPTIONAL_QUERY = 0,
  OPTIONAL_JSON = 1,
  OPTIONAL_FORM = 2,
  REQUIRED_QUERY = 'REQUIRED_QUERY',
  REQUIRED_JSON = 'REQUIRED_JSON',
  REQUIRED_FORM = 'REQUIRED_FORM',
}
export const enum Ry {
  JSON = 0,
  BLOB = 1,
}

export class ParamTy {
  public static Query<T = any>() {
    return Py.REQUIRED_QUERY as ApiRequiredParamTy<T>;
  }

  public static Json<T = any>() {
    return Py.REQUIRED_JSON as ApiRequiredParamTy<T>;
  }

  public static Form<T = any>() {
    return Py.REQUIRED_FORM as ApiRequiredParamTy<T>;
  }

  public static OptionalQuery<T = any>() {
    return Py.OPTIONAL_QUERY as ApiOptionalParamTy<T>;
  }

  public static OptionalJson<T = any>() {
    return Py.OPTIONAL_FORM as ApiOptionalParamTy<T>;
  }

  public static OptionalForm<T = any>() {
    return Py.OPTIONAL_JSON as ApiOptionalParamTy<T>;
  }
}
export class RespTy {
  public static Json<T>() {
    return Ry.JSON as unknown as ApiRespTy<T>;
  }

  public static Blob() {
    return Ry.BLOB as unknown as ApiRespTy<Blob>;
  }
}

export enum HttpMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  HEAD = 'HEAD',
  DELETE = 'DELETE',
}
