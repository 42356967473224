"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.systemLang = void 0;
/**
 * 获取系统语言，没有则返回 null
 *
 * @author HUANGE
 * @date 2020-03-10
 * @returns
 */
const systemLang = () => {
    const { language, languages } = navigator;
    if (language) {
        return language;
    }
    console.log(["=>language", language]);
    if (Array.isArray(languages) && languages.length > 0) {
        return languages[0];
    }
    return null;
};
exports.systemLang = systemLang;
