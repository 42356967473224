import { REQUEST_SEVER } from '../request';

export const urlMap = {
  [REQUEST_SEVER.DEFAULT]: {
    client: import.meta.env.VITE_REQUEST_BASE_URL,
    server: import.meta.env.VITE_REQUEST_BASE_URL_SERVER,
  },
  [REQUEST_SEVER.COMMON]: {
    client: import.meta.env.VITE_REQUEST_COMMON_URL,
    server: import.meta.env.VITE_REQUEST_COMMON_URL_SERVER,
  },
  [REQUEST_SEVER.DTC]: {
    client: import.meta.env.VITE_REQUEST_DTC_URL,
    server: import.meta.env.VITE_REQUEST_DTC_URL_SERVER,
  },
} as const;
export function dispatchBaseURL(type: REQUEST_SEVER) {
  const { client, server } = urlMap[type];
  return import.meta.client ? client : server;
}

// 不支持 for in 循环 和 Object.keys 遍历
export const enum RespCode {
  SUCCESS = 0,
  NOT_EXIST = 90104,
  REPEAT_OPERATION = 90105,
  TOKEN_EXPIRED = 20101,
}
