import type { Ref } from 'vue';
import { parseApiOptions } from './_helper';
import type { ApiOptions, DefaultApiOptions, DefineApi, RespWarp } from './types';
import { RespCode } from './config';
import { $fetch } from 'ofetch';

export function createDefineApi(opts?: DefaultApiOptions): DefineApi {
  const defuOpts = { ...opts };
  const _fetch = createFetch(defuOpts);
  return (options: ApiOptions): any => {
    return {
      async fetch(params?: Record<string, any>) {
        const opts = parseApiOptions(options, params);
        return await _fetch(opts.url, opts);
      },
      async useFetch(params?: Record<string, any>, opts?: ApiOptions) {
        const _opts = parseApiOptions({ ...options, ...opts }, params);
        const resp = await useFetch<any>(_opts.url, {
          ..._opts,
          watch: false,
          $fetch: _fetch,
        } as any);
        const data = computed<any>({
          get() {
            const data: Ref<any> = resp.data;
            return data.value?.data;
          },
          set(val: any) {
            (resp.data.value as any).data = val;
          },
        });
        if (_opts.autoHandleError && resp.data.value?.code === RespCode.NOT_EXIST) {
          useErrorPage({
            statusCode: 404,
          });
        }
        return [data, resp];
      },
    };
  };
}

function createFetch(opts?: DefaultApiOptions) {
  const defuOpts = { ...opts };
  const _fetch = $fetch.create(defuOpts);
  return async (url: string, opts: any = {}) => {
    try {
      const res: RespWarp<any> = await _fetch(url, opts);
      if (res.code && res.code !== RespCode.SUCCESS) {
        console.warn(`[api]: ${url} error`);
        console.error('[api]: ' + (res.message ?? 'Fetch error'));
      }
      return res;
    } catch (err) {
      console.warn(`[api]: ${url} error`);
      console.error(err);
      throw err;
    }
  };
}
