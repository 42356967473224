export enum REQUEST_SEVER {
  DEFAULT = 'default',
  COMMON = 'common',
  DTC = 'dtc',
}

export enum RESPONSE_CODE {
  SUCCESS = 0,
  NOT_EXIST = 90104,
}

export enum COMMUNITY_BASE_DATA {
  DEVICE = 'community_device',
  POWER = 'community_laser_power',
  MATERIAL = 'community_material',
  SOFTWARE = 'community_software',
  DISCUSSION = 'discussion_category',
  TOPICS = 'community_question_topic',
  PROCESS = 'community_processing_type',
  BITMAP = 'community_bitmap_mode',
}

export enum BLOCK_TYPE {
  HOME = 'home',
  PREMIUM_HOME = 'premium-home',
  INSPIRATION = 'inspiration',
  TIPS = 'tips',
  HOWTOS = 'howtos',
}

export enum CONTENT_TYPE {
  HOWTOS = 'howtos',
  TIPS = 'tips',
  INSPIRATION = 'inspiration',
  DISCUSSION = 'discussion',
  QUESTION = 'question',
}

export enum OBJECT_TYPE {
  MAKING = 'making',
  COLLECTION = 'collection',
  QUESTION = 'question',
  DISCUSSION = 'discussion',
  ANSWER = 'answer',
  COMMENT = 'comment',
  USER = 'user',
  BLOCKITEM = 'blockItem',
}

export enum USER_ACTION {
  LIKE = 'like',
  UNLIKE = 'unlike',
  VIEW = 'VIEW',
  FAVORITE = 'favorite',
  FOLLOW = 'follow',
  COMMENT = 'comment',
}

// 机型标签颜色
export const deviceTagColorMap = {
  d1: '#6E6B8B',
  p1: '#4F4E4D',
  m1: '#846e5e',
};

export const uploadConfigKey = 'xtool-community';

export const Toasts = {
  error: 'Sorry, something went wrong. Please try again later.',
};

export const UploadType = {
  Photo: 'photo',
  Attachment: 'attachment',
  StepPhoto: 'stepPhoto',
};

export const AddVideoType = {
  Video: 'video',
  StepVideo: 'stepVideo',
};

export interface WhitesType {
  sale_maker: boolean;
  xthings_maker: boolean;
}

export const isInIframe = () => window.self !== window.parent;
