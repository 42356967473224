import { createDefineApi } from './create';
import { dispatchBaseURL } from './config';
import { createHandlers } from './handler';

const handlers = createHandlers();

export const defineCommonApi = createDefineApi({
  ...handlers,
  baseURL: dispatchBaseURL(REQUEST_SEVER.COMMON),
});

export const defineFindApi = createDefineApi({
  ...handlers,
  baseURL: dispatchBaseURL(REQUEST_SEVER.DEFAULT),
});

export const defineDtcApi = createDefineApi({
  ...handlers,
  baseURL: dispatchBaseURL(REQUEST_SEVER.DTC),
});
