import { get } from 'lodash-es';
import { Py } from './const';
import type { ApiOptions } from './types';

const defuApiOpts = {
  method: 'GET',
  paramTy: ParamTy.OptionalQuery(),
  respTy: RespTy.Json(),
};

export function parseApiOptions(opts: ApiOptions, params: any) {
  opts = { ...defuApiOpts, ...opts };
  if (opts.method === HttpMethod.POST) {
    opts.paramTy = ParamTy.OptionalJson() ?? opts.paramTy;
  }
  parseURL(opts, params);
  parseApiParams(opts, params);
  return opts;
}

export function parseApiParams(opts: any, params: any) {
  if ([Py.OPTIONAL_QUERY, Py.REQUIRED_QUERY].includes(opts.paramTy)) {
    opts.params = params ?? {};
  } else if (![HttpMethod.GET, HttpMethod.HEAD].includes(opts.method)) {
    opts.body = params ?? {};
  } else {
    throw new Error('json/form is not allowed in GET/HEAD method');
  }
  return opts;
}

export function parseURL(opts: any, params: any = {}) {
  let url: string = opts.url;
  const matches = url.matchAll(/:([\w.]+)/g);
  if (matches === null) {
    return opts;
  }
  params = toValue(params);
  Array.from(matches).forEach((match) => {
    url = url.replace(match[0], get(params, match[1] as any));
  });
  opts.url = url;
  return opts;
}
