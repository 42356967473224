"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LANG_KEY = exports.TOKEN_KEY = exports.MESSAGE_ORIGIN_NAME = exports.POST_MESSAGE_SOURCE = void 0;
exports.POST_MESSAGE_SOURCE = "makeblock-passport";
exports.MESSAGE_ORIGIN_NAME = "passport-client";
/**
 * 用户 Token 缓存 key
 */
exports.TOKEN_KEY = "utoken";
/**
 * 语言缓存 KEY
 */
exports.LANG_KEY = "IntlLang";
