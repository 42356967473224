interface ErrorPageProps {
  statusMessage?: string;
  description?: string;
  statusCode?: number;
  redirectUrl?: string;
  redirectText?: string;
}

export function useErrorPage(props: ErrorPageProps) {
  const error = {
    statusCode: props.statusCode || 500,
    statusMessage:
      props.statusMessage ||
      "Oops! We couldn't find the page you were looking for.",
    data: {
      description:
        props.description ||
        'Please double-check the URL and try again, or return to our homepage to continue browsing.',
      redirectUrl: props.redirectUrl || '/',
      redirectText: props.redirectText || 'Go to Homepage',
    },
  };

  const handler = process.client ? showError : createError;

  throw handler(error);
}
