"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SYSTEM_TYPE = exports.XTOOL_PLATFORM = exports.MAKEBLOCK_PLATFORM = exports.AREA = exports.DOMAIN_SPACE = exports.MESSAGE_TYPE = exports.CLIENT_ENV = exports.PAGE_MODE = void 0;
var PAGE_MODE;
(function (PAGE_MODE) {
    PAGE_MODE["PAGE"] = "page";
    PAGE_MODE["WIN"] = "win";
    PAGE_MODE["FULL_WIN"] = "full-win";
})(PAGE_MODE || (exports.PAGE_MODE = PAGE_MODE = {}));
var CLIENT_ENV;
(function (CLIENT_ENV) {
    CLIENT_ENV["DEV"] = "dev";
    CLIENT_ENV["TEST"] = "test";
    CLIENT_ENV["PROD"] = "prod";
    CLIENT_ENV["LOCAL"] = "local";
})(CLIENT_ENV || (exports.CLIENT_ENV = CLIENT_ENV = {}));
var MESSAGE_TYPE;
(function (MESSAGE_TYPE) {
    MESSAGE_TYPE["LOGIN"] = "login";
    MESSAGE_TYPE["LOGOUT"] = "logout";
    MESSAGE_TYPE["REGISTER"] = "register";
    MESSAGE_TYPE["FINDPWD"] = "findpwd";
    MESSAGE_TYPE["SWITCH_HSOT"] = "switchHost";
})(MESSAGE_TYPE || (exports.MESSAGE_TYPE = MESSAGE_TYPE = {}));
var DOMAIN_SPACE;
(function (DOMAIN_SPACE) {
    DOMAIN_SPACE["MAKEBLOCK"] = "makeblock";
    DOMAIN_SPACE["XTOOL"] = "xtool";
})(DOMAIN_SPACE || (exports.DOMAIN_SPACE = DOMAIN_SPACE = {}));
var AREA;
(function (AREA) {
    AREA["EU"] = "eu";
    AREA["CN"] = "cn";
    AREA["US"] = "us";
})(AREA || (exports.AREA = AREA = {}));
var MAKEBLOCK_PLATFORM;
(function (MAKEBLOCK_PLATFORM) {
    // 慧编程ide，新接入的都以下这个字段为准，不要使用MBLOCK_WEB、MBLOCK_PC、MBLOCK_MOBILE
    MAKEBLOCK_PLATFORM["MBLOCK_5"] = "mblock_5";
    // 网页端慧编程IDE
    MAKEBLOCK_PLATFORM["MBLOCK_WEB"] = "mblock_web";
    // PC端慧编程IDE
    MAKEBLOCK_PLATFORM["MBLOCK_PC"] = "mblock_pc";
    // 移动端慧编程IDE
    MAKEBLOCK_PLATFORM["MBLOCK_MOBILE"] = "mblock_mobile";
    // 壹盒
    MAKEBLOCK_PLATFORM["ONE_BOX"] = "one_box";
    // STEAM教育服务平台学生
    MAKEBLOCK_PLATFORM["STEAM_PLATFORM_STU"] = "steam_platform_stu";
    // STEAM教育服务平台老师
    MAKEBLOCK_PLATFORM["STEAM_PLATFORM_TEACHER"] = "steam_platform_teacher";
    // 作品社区
    MAKEBLOCK_PLATFORM["COMMUNITY"] = "community";
    // 扩展设计器
    MAKEBLOCK_PLATFORM["EXT_BUILDER"] = "ext_builder";
    // MakeX赛事
    MAKEBLOCK_PLATFORM["MAKEX"] = "makex";
    // Makeblock D2V 中台系统
    MAKEBLOCK_PLATFORM["MAKE_D2V"] = "makeblock_d2v";
})(MAKEBLOCK_PLATFORM || (exports.MAKEBLOCK_PLATFORM = MAKEBLOCK_PLATFORM = {}));
var XTOOL_PLATFORM;
(function (XTOOL_PLATFORM) {
    XTOOL_PLATFORM["XCS"] = "xcs";
    XTOOL_PLATFORM["PROJECTS"] = "projects";
    XTOOL_PLATFORM["DESIGN_FIND"] = "design_find";
    XTOOL_PLATFORM["SUPPORT"] = "support";
    XTOOL_PLATFORM["X_ART"] = "xart";
    XTOOL_PLATFORM["SMB"] = "smb";
    XTOOL_PLATFORM["CUSTOM_THINGS"] = "custom_things";
    XTOOL_PLATFORM["PASSPORT"] = "passport";
    XTOOL_PLATFORM["FOCUSAUR"] = "focusaur";
    XTOOL_PLATFORM["XCS_CMS"] = "xcs_cms";
    XTOOL_PLATFORM["MARKETING"] = "marketing";
    XTOOL_PLATFORM["MATERIALS_LAB"] = "materials_lab";
})(XTOOL_PLATFORM || (exports.XTOOL_PLATFORM = XTOOL_PLATFORM = {}));
var SYSTEM_TYPE;
(function (SYSTEM_TYPE) {
    SYSTEM_TYPE["PC"] = "pc";
    SYSTEM_TYPE["PAD"] = "pad";
    SYSTEM_TYPE["MOBILE"] = "mobile";
    SYSTEM_TYPE["APP"] = "app";
    SYSTEM_TYPE["OS"] = "os";
})(SYSTEM_TYPE || (exports.SYSTEM_TYPE = SYSTEM_TYPE = {}));
